@font-face {
  font-family: "SB Agro B";
  src: url("/src/assets/fonts/SB AgroOTF B.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SB Agro M";
  src: url("/src/assets/fonts/SB AgroOTF M.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SB Agro L";
  src: url("/src/assets/fonts/SB AgroOTF L.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* 특정 요소에 폰트 적용 */
body {
  margin: 0;
  font-family: "SB Agro L", sans-serif;
  overflow-x: hidden;
  width: 100%; /* 부모 요소가 100%의 너비를 차지하도록 설정 */
}
button {
  cursor: pointer;
  background-color: #e26d3d;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff8c66; /* Hover 색상 제안 */
}

h1,
h2 {
  text-align: center;
}

h1 {
  font-size: 3em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  color: #555;
}

a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #e26d3d;
}

.sectionArea {
  position: relative; /* 자식 요소가 절대 위치를 가질 수 있도록 */
  color: #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* 배경 이미지 위치 조정 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 92%;
  margin: 0px auto; /* 가운데 정렬 */
  /* background-color: blue; */
}

.theTitle {
  font-weight: bold;
  color: #333;
  margin: 50px 0px;
  font-size: 3em;
  font-weight: 900;
}

.theTitleSub {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 50px;
  line-height: 2;
}

.theTitleSub-text {
  position: relative;
  display: inline-block;
  color: black;
  font-weight: bold;
  padding: 5px;
  font-weight: 600;
  line-height: 1.5;
}

.theTitleSub-text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 9px;
  font-size: 1.2rem;

  width: 100%;
  height: 20%;
  background-color: #ffc107;
  z-index: -1;
}

.showThisFirstAndHideInSmall {
  width: 100%;
}

.showThisSecondAndHideTheFirst {
  display: none;
}

.justShow {
  margin: 20px 0;
}

.normalize {
  display: none;
}

@media screen and (max-width: 960px) {
  .theTitle {
    font-size: 2.3em;
  }

  .theTitleSub {
    font-size: 1.4rem;
    color: #555;
  }
  .theTitleSub-text span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .theTitle {
    font-size: 1.9em;
  }

  .theTitleSub {
    font-size: 1.2rem;
    color: #555;
  }
  .theTitleSub-text span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 640px) {
  .theTitle {
    font-size: 1.25em;
  }

  .theTitleSub {
    font-size: 1rem;
    color: #555;
  }
}

.showThisFirstAndHideInSmall {
  display: none;
}

.showThisSecondAndHideTheFirst {
  display: block;
}

@media screen and (max-width: 425px) {
  .theTitle {
    font-size: 1.1em;
  }

  .highlight-black {
    font-size: 0.8em;
  }

  .highlight-text {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 375px) {
  .header-text-landing01 h1 {
    font-size: 0.9rem;
    font-weight: normal;
    margin-bottom: 0px;
    word-wrap: break-word;
    white-space: normal;
    animation: seesaw 0.5s ease-in-out 5;
  }

  .theTitle {
    font-size: 1.3em;
    letter-spacing: -0.8px;
  }

  .theTitleSub {
    font-size: 1em;
    letter-spacing: -0.5px;
  }
}

@media screen and (max-width: 350px) {
  .theTitle {
    font-size: 1.1em;
  }

  .theTitleSub {
    font-size: 0.9rem;
  }
}
