/* 백드롭 */

/* 콘텐츠 */

.contents {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  max-width: 1200px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  margin: 100px auto;
}

/* 리스트 */
.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* 행 */
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
}

/* 왼쪽 아이템 */
.left-item img {
  width: 100%;
  object-fit: contain;
}

/* 오른쪽 아이템 */
.right-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* 오른쪽 타이틀 */
.right-title {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
}

/* 오른쪽 텍스트 */
.right-side-text {
  color: #333;
  font-size: 1rem;
}
