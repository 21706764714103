/* 배경 어둡게 하고 blur 처리 */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 어둡게 */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* 배경 블러 처리 */
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 60%;
}

.modal-image {
  width: 100%;
  /* max-width: 60%; */
  /* max-height: 20%; */
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 36px;
  height: 36px;
  background-color: #e26d3d;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 1;
  top: -1px;
  right: 0;
}

.modal-close img {
  width: 36px;
  height: 36px;
}

/* .modal-close:hover {
  background-color: #ff8c66;
} */
