.program-introduction {
  display: flex;
  flex-direction: column;
}

.intro-section {
  width: 100vw;
  height: 360px;
  background-color: #eeeadf;
  padding-top: 100px;
}

.inner-intro-section {
  width: 100%;
  max-width: 1080px;
  text-align: left;
  margin: 0 auto;
}

.intro-title {
  font-size: 3.5em;
  font-weight: 700;
  text-align: left;
  margin-top: 2%;
}

.intro-subtitle {
  font-size: 1.2em;
  color: #555;
}

.second-section {
  width: 100%;
  margin-top: 60px;
  background-color: #ffff;
  text-align: center;
}

.second-title {
  font-size: 2em;
  font-weight: bold;
  line-height: 1.5;
  color: #000;
}

.highlight-orange {
  color: #e26d3d;
}

.container {
  display: grid;
  max-width: 1080px;
  margin: 0 auto;
  gap: 20px;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
}

.card {
  display: flex;
  flex-direction: column; /* 세로로 정렬 */
  align-items: center; /* 가로 중앙 정렬 */
  justify-content: center; /* 세로 중앙 정렬 */
  overflow: hidden;
  text-align: center;
  background-color: #fff;
}

.image {
  width: 100%;
  max-width: 240px;
  height: 240px;
  border-radius: 10px;
  background-color: #eee;
  border-bottom: 2px solid #eee;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.text {
  padding: 10px 10px;
  max-width: 220px;
  width: 100%;
  line-height: 20px;
  /* height: 100px; */
  font-size: 14px;
  border-radius: 10px;
  background-color: #e08a50;
  color: #fff;
  /* font-weight: bold; */
  margin-top: 10px;
}

.premium-content {
  padding: 60px 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.premium-content h1 {
  font-size: 28px;
  font-weight: bold;
}

.premium-content p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.highlight {
  color: #e08a50;
  font-weight: bold;
  font-size: 3rem;
}

.curriculum {
  width: 100vw;
  height: fit-content;
  /* height: 550px; */
  background-color: #eeeadf;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* padding-top: 100px; */
}

@media screen and (max-width: 1080px) {
  .intro-section,
  .inner-intro-section {
    max-width: 960px;
  }
  .intro-title {
    font-size: 3em;
    margin-top: 5%;
  }
  .intro-subtitle {
    font-size: 1.1em;
  }
  .second-title {
    font-size: 1.8em;
  }
  .container {
    padding: 18px;
    grid-template-columns: repeat(4, 1fr);
  }
  .text {
    font-size: 13px;
  }
  .premium-content {
    padding: 50px 18px;
  }
  .premium-content h1 {
    font-size: 26px;
  }
  .premium-content p {
    font-size: 15px;
  }
}

@media screen and (max-width: 980px) {
  .intro-section,
  .curriculum {
    padding-top: 70px;
  }
  .inner-intro-section {
    max-width: 840px;
  }
  .intro-title {
    font-size: 2.8em;
    margin-top: 5%;
  }
  .intro-subtitle {
    font-size: 1em;
  }
  .second-title {
    font-size: 1.6em;
  }
  .container {
    padding: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .text {
    font-size: 12px;
  }
  .premium-content {
    padding: 40px 16px;
  }
  .premium-content h1 {
    font-size: 24px;
  }
  .premium-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .intro-section,
  .curriculum {
    padding-top: 60px;
  }
  .inner-intro-section {
    max-width: 720px;
  }
  .intro-title {
    font-size: 2.5em;
    margin-top: 8%;
  }
  .intro-subtitle {
    font-size: 0.9em;
  }
  .second-title {
    font-size: 1.4em;
  }
  .container {
    padding: 14px;
    grid-template-columns: repeat(2, 1fr);
  }
  .text {
    font-size: 11px;
  }
  .premium-content {
    padding: 30px 14px;
  }
  .premium-content h1 {
    font-size: 22px;
  }
  .premium-content p {
    font-size: 13px;
  }
}

@media screen and (max-width: 375px) {
  .intro-section,
  .curriculum {
    padding-top: 50px;
  }
  .inner-intro-section {
    max-width: 360px;
  }
  .intro-title {
    font-size: 2em;
    margin-top: 20%;
  }
  .intro-subtitle {
    font-size: 0.8em;
  }
  .second-title {
    font-size: 1.2em;
  }
  .container {
    padding: 12px;
    grid-template-columns: repeat(1, 1fr);
  }
  .text {
    font-size: 14px;
  }
  .premium-content {
    padding: 20px 12px;
  }
  .premium-content h1 {
    font-size: 20px;
  }
  .premium-content p {
    font-size: 12px;
  }
}

/* 애니메이션 */

.card {
  opacity: 0;
  transform: translateY(20px); /* 아래에서 올라오는 느낌 */
  animation: fadeInUp 1s ease forwards;
  animation-delay: calc(
    var(--index) * 0.1s
  ); /* 각 카드의 index에 따라 딜레이 적용 */
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
