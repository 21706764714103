.landing-detail-05 {
  text-align: center;
  padding: 60px 0;
  background-color: #f5f5f7;
}

.landing-detail-05 .banner-title {
  font-size: 2em;
  font-weight: bold;
  color: #c46ed9;
}

.HowToStartInfo {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.position-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2;
}

.side-image {
  width: 100px;
  height: auto;
}

.side-image.left {
  margin-right: 20px;
}

.side-image.right {
  margin-left: 20px;
}

.landing-detail-05 .content {
  margin-top: 30px;
}

.landing-detail-05 .conversation-section {
  margin-bottom: 60px;
}

.landing-detail-05 .conversation-box .speech-bubble {
  display: flex;
  align-items: flex-end;
}

.landing-detail-05 .conversation-box .speech-bubble img {
  width: 50px;
}

.landing-detail-05 .conversation-box .speech-bubble .img-blank {
  width: 50px;
  height: 40px;
}

.landing-detail-05 .conversation-box .user-text p {
  background-color: #acd7fe;
  width: 100%;
  max-width: 100%;
  font-size: 1em;
  line-height: 1.2em;
  padding: 10px 50px 10px 20px;
  box-sizing: border-box;
  font-weight: 500;
  height: 50px;
  border-radius: 10px 10px 10px 0;
  margin: 0 0 0 10px;
}

.landing-detail-05 .conversation-box .ai-text p {
  text-align: left;
  width: 100%;
  max-width: 100%;
  background-color: #ffe0c6;
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 600;
  padding: 10px 30px 10px 20px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 10px;
  margin: 0 10px 0 0;
}

.landing-detail-05 .conversation-box .ai-text {
  flex-direction: row-reverse;
}

.landing-detail-05 .user-text {
  font-size: 1em;
  color: #000;
  margin-bottom: 10px;
}

.landing-detail-05 .ai-text {
  font-size: 0.9em;
  color: #555;
  margin-bottom: 10px;
}

.landing-detail-05 .user-translation {
  font-size: 0.9em;
  font-style: italic;
  color: #888;
}

/* Features Section */
.landing-detail-05 .features-section {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* 좌우 끝으로 정렬 */
  align-items: flex-start; /* 위쪽에 정렬 */
}

.landing-detail-05 .feature {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
}

.landing-detail-05 .feature-title {
  padding: 10px;
  border-radius: 20px;
  background-color: #de6d3d;
  display: block;
  width: 60%;
  margin: 30px auto 30px;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  height: 34px;
}

.feature-description {
  /* margin-top: 320px; */
  font-size: 1em;
  width: 100%;
  color: #333;
  line-height: 1.5;
  text-align: center; /* 텍스트 가운데 정렬 */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); /* 자신의 가로 크기 절반만큼 이동 */
}

.feature .feature-box {
  width: 95%;
  height: 400px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  display: inline-block; /* 텍스트를 이미지와 함께 배치 */
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10%;
}

.speaking-test {
  background-image: url("../../assets/images/speaking-test-img.png");
  background-size: 100% auto;
  background-repeat: no-repeat; /* 배경 이미지 반복 방지 */
  background-position: center 0px; /* 세로 방향을 위로 10px 이동 */
  width: 100%; /* 컨테이너 크기 */
  text-align: center;
}

.free-discussion {
  background-image: url("../../assets/images/free-discussion.png");
  background-size: 95% auto;
  background-repeat: no-repeat; /* 배경 이미지 반복 방지 */
  background-position: center 5px; /* 배경 이미지를 가운데 정렬 */
  text-align: center;
}

.title-font-size {
  font-size: 1.8em !important;
}

/* Animation */
.speech-bubble {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.speech-bubble.visible {
  opacity: 1;
  transform: translateY(0);
}

.hidden {
  display: none;
}

.speech-bubble.visible.hidden {
  display: block;
}

/* 추가된 부분: Title Section 이미지 배치 */
.title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.title-content {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .landing-detail-05 .features-section {
    display: block;
  }

  .landing-detail-05 .speaking-test,
  .landing-detail-05 .free-discussion {
    width: 90%; /* 100%로 확장하여 한 줄에 배치 */
    margin: 10px 0; /* 간격 조정 */
    display: block; /* 인라인 블록을 없애고 블록 요소로 설정 */
  }

  .landing-detail-05 .feature {
    width: 100%;
  }

  .speaking-test {
    background-size: 80% auto;
  }

  .free-discussion {
    background-size: 80% auto;
  }

  .feature .feature-box {
    width: 80%;
    height: 500px;
  }

  .feature-description {
    font-size: 1.3em;
  }

  .landing-detail-05 .feature-title {
    margin-top: 90px;
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .landing-detail-05 .subtitle {
    font-size: 2em;
  }
  .feature .feature-box {
    width: 95%;
    height: 400px;
  }
  .landing-detail-05 .conversation-box .user-text p {
    padding: 10px 30px 10px 20px;
  }

  .landing-detail-05 .conversation-box .ai-text p {
    padding: 10px 20px 10px 20px;
  }
}

@media screen and (max-width: 375px) {
  .landing-detail-05 .subtitle {
    font-size: 2em;
  }
  .feature .feature-box {
    font-size: 0.9em;
  }

  .landing-detail-05 .feature-title {
    margin-top: 90px;
    width: 80%;
  }
}
