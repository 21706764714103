.content-program-table-container {
  text-align: center;
  padding: 20px;
  margin-top: 300px;
  width: 100%;
  box-sizing: border-box;
}

.content-program-title {
  font-size: 2rem;
  color: #e47936;
  margin-bottom: 20px;
}

.gap {
  width: 100%;
  height: 10px;
}

/* 기본 스타일 */
.content-program-table {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  border-collapse: collapse;
  table-layout: auto;
}

.content-program-table th,
.content-program-table td {
  padding: 8px;
  word-wrap: break-word;
}

/* 작은 화면에서는 글씨 크기와 여백을 줄이기 */
@media (max-width: 768px) {
  .content-program-title {
    font-size: 1.5rem;
  }

  .content-program-table th,
  .content-program-table td {
    font-size: 0.7rem;
    padding: 4px;
  }

  .category {
    width: 80px;
    font-size: 0.7rem;
  }
}

/* 아주 작은 화면에서는 테이블이 스크롤 가능하게 만들기 */
@media (max-width: 480px) {
  .content-program-table-container {
    overflow-x: auto; /* 가로 스크롤 허용 */
  }

  .content-program-table {
    min-width: 600px; /* 최소 테이블 너비 설정 */
  }

  .content-program-table th,
  .content-program-table td {
    font-size: 0.6rem;
    padding: 3px;
  }
}

.content-program-table th,
.content-program-table td {
  width: auto;
  word-wrap: break-word;
}

.content-program-table td.category {
  font-size: 0.9rem;
}

.th-first {
  border-radius: 10px 0 0 10px;
}

.th-last {
  border-radius: 0 10px 10px 0;
}

.content-program-table td {
  font-size: 0.8rem;
  font-weight: 700;
}

.category-header {
  background-color: #f1f1f1;
  font-weight: bold;
  border-radius: 10px;
}

.unit {
  background-color: #dddddd;
}

.horizontal-gap {
  width: 6px;
}

.category {
  width: 100px;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 10px;
  height: 100px;
}

.nuri {
  background-color: #c9548f;
}

.nuri-unit {
  color: #c9548f;
  background-color: #f2d4e3;
  border-right: 1px dashed #c9548f;
}

.ccss {
  background-color: #4ba5a0;
}

.ccss-unit {
  color: #4ba5a0;
  background-color: #d2e8e7;
  border-right: 1px dashed #4ba5a0;
}

.sf {
  background-color: #d9a623;
}

.sf-unit {
  color: #d9a623;
  background-color: #f6e9c9;
  border-right: 1px dashed #d9a623;
}

.non-fiction {
  background-color: #8b7ec4;
  color: white;
}

.non-fiction-unit {
  color: #8b7ec4;
  background-color: #e1dff0;
  border-right: 1px dashed #8b7ec4;
}

.adventure {
  background-color: #e1825f;
  color: white;
}

.adventure-unit {
  color: #e1825f;
  background-color: #f8e0d7;
  border-right: 1px dashed #e1825f;
}

.daily-life {
  background-color: #6dac6d;
  color: white;
}

.daily-life-unit {
  color: #6dac6d;
  background-color: #ddecdd;
  border-right: 1px dashed #6dac6d;
}

.daily-conversation {
  background-color: #b0745b;
  color: white;
}

.daily-conversation-unit {
  color: #b0745b;
  background-color: #eaddd6;
  border-right: 1px dashed #b0745b;
}

.first {
  border-radius: 10px 0 0 10px;
}

.last {
  border-radius: 0 10px 10px 0;
  border-right: 0px;
}
