.how-to-start {
  background-color: #ffffff; /* 배경색: 흰색 */
  color: #de6d3d; /* 텍스트 기본 색상: 주황색 */
  padding: 40px;
}

.section-header {
  text-align: center;
  margin-bottom: 40px;
}

.section-header h2 {
  font-size: 2.8em;
  font-weight: bold;
}

.section-header p {
  font-size: 18px;
  margin-top: 10px;
  color: #999; /* 서브 텍스트 색상 */
}

.content {
  display: flex;
  justify-content: space-between;
}

.left-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-content .step_title {
  font-size: 1.7em;
  font-weight: 900;
  width: 65%;
}

.right-content .step_sub_title {
  color: #eda356;
  font-size: 0.7em;
  font-weight: 700;
}

.post {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
}

.post .step {
  width: 14%;
  max-width: 200px;
  font-size: 14px;
  margin-right: 20px;
  color: #999;
}

.post h3 {
  font-size: 18px;
  flex: 1;
}

.how-to-start .modal-image {
  width: 20%;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 1024px) {
  .right-content .step_title {
    font-size: 1.4em;
    font-weight: 900;
    width: 65%;
  }
  .right-content .step_sub_title {
    color: #feb540;
    font-size: 0.5em;
    font-weight: 700;
  }
}

@media (max-width: 960px) {
  .post {
    flex-direction: column; /* 세로 방향으로 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
  }

  .modal-image {
    width: 100%; /* 이미지 크기 조정 */
    margin-top: 20px; /* 이미지와 텍스트 간의 간격 추가 */
    object-fit: cover;
  }
  .right-content .step_title {
    font-size: 1.5em;
    width: 100%;
  }
  .left-content {
    display: none;
  }
}

@media (max-width: 768px) {
  .how-to-start .modal-image {
    width: 100%;
  }

  .grid-container-images {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }
}

@media (max-width: 425px) {
  .how-to-start .modal-image {
    width: 100%;
  }

  .grid-container-images {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }

  .section-header h2 {
    font-size: 1.7em;
  }

  .right-content .step_title {
    font-size: 1.15em;
  }
}

@media (max-width: 375px) {
  .how-to-start {
    padding: 20px;
  }

  .post .step {
    width: 25%;
    max-width: 200px;
    font-size: 14px;
    margin-right: 20px;
    color: #999;
  }

  .right-content .step_sub_title {
    color: #feb540;
    font-size: 0.7em;
    font-weight: 700;
  }
}
