/* Header.css */

@font-face {
  font-family: "SB Agro B";
  src: url("/src/assets/fonts/SB AgroOTF B.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SB Agro M";
  src: url("/src/assets/fonts/SB AgroOTF M.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SB Agro L";
  src: url("/src/assets/fonts/SB AgroOTF L.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 세로 중앙 정렬 */
  padding: 10px 0px;
  background-color: rgba(255, 255, 255, 0.5); /* Transparency 50% */
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 89;
  transition: background-color 0.3s ease;
  height: 80px;
}

.header.scrolled {
  background-color: rgba(255, 255, 255, 1); /* 스크롤 시 Transparency 100% */
}

.logo img {
  width: 100px;
  padding: 10px 20px;
}

.menu {
  display: flex;
  align-items: center; /* 세로 중앙 정렬 추가 */
  margin-right: 50px;
  gap: 20px; /* 메뉴 간 기본 간격 */
}

.menu-link {
  width: fit-content;
  font-size: 16px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.menu-link:hover {
  color: #e26d3d;
}

.header_button {
  font-family: "SB Agro L";
  width: 100px;
  height: 40px; /* 고정 높이 */
  background-color: #e26d3d;
  color: white;
  border: none;
  cursor: pointer;
  display: flex; /* Flexbox를 사용하여 텍스트를 가운데 정렬 */
  justify-content: center; /* 가로 가운데 정렬 */
  align-items: center; /* 세로 가운데 정렬 */
  transition: background-color 0.3s ease;
}

.header_button:hover {
  background-color: #ff8c66; /* Hover 색상 */
}

.hamburger {
  display: none;
  font-size: 40px;
}

/* 프로그램 링크 스타일 */
.program-links {
  display: flex;
  gap: 40px; /* 항목 간격 */
  animation: fadeIn 0.3s ease forwards; /* 기본으로 fadeIn 애니메이션 */
}

.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

.program-link {
  font-size: 16px;
  color: #e26d3d;
  cursor: pointer;
  transition: color 0.3s ease;
}

.program-link:hover {
  color: #ff8c66;
}
/* 
@media screen and (max-width: 768px) {
  .menu {
    display: none;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    padding-right: 2vh;
  }
} */

.hidden {
  display: none; /* 헤더를 화면에서 완전히 숨김 */
}
