.contents-samples {
  padding-bottom: 30px;
  background-color: #eeeadf;
}

.title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.highlight {
  color: #e26d3d;
}

.slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  transition: opacity 1.5s ease-in;
}

.slider-container.fade-in {
  opacity: 1;
}

/* 좌에서 우로 스크롤 */
@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* 우에서 좌로 스크롤 */
@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.small-text {
  font-size: 1.1em; /* 글자 크기를 작게 설정 */
  text-align: center; /* 가운데 정렬 */
  color: #777; /* 회색 계열의 글자 색상 */
  margin-top: 50px;
}
