.how-to-buy-options {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 40px;
  width: 100%; /* 전체 너비를 설정하여 옵션 섹션을 넓게 만들기 */
}

.purchase-option {
  display: flex;
  align-items: flex-start; /* Aligns text to the top for vertical alignment */
  justify-content: flex-start; /* Ensures horizontal alignment to the left */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 45%;
  flex-direction: row; /* 기본적으로 수평 배치 */
}

.purchase-option:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.purchase-icon {
  width: 100px;
  margin-top: 24px;
  margin-right: 20px; /* Keeps space between the icon and the text */
}

.purchase-text {
  margin-left: 10%;
  text-align: left; /* Ensures all text inside is left-aligned */
}

.purchase-text h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.purchase-text p {
  font-size: 1em;
  color: #777;
}

/* 반응형 디자인 */

@media screen and (max-width: 1024px) {
  .justShow {
    display: block;
  }
  .normalize {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .how-to-buy-options {
    flex-direction: column;
    align-items: center;
  }

  .purchase-option {
    width: 80%;
    margin-bottom: 20px;
  }

  .how-to-buy-title {
    width: 100%;
  }

  .how-to-buy-image {
    display: none; /* 모바일에서 이미지를 숨김 */
  }
}

@media screen and (max-width: 768px) {
  .justShow {
    display: none;
  }

  .normalize {
    display: block;
  }
  .purchase-option {
    flex-direction: column;
    align-items: center; /* 수직 정렬을 중앙으로 설정 */
  }

  .purchase-text {
    margin-left: 0; /* 모바일에서 텍스트 좌측 여백 제거 */
    margin-top: 10px; /* 아이콘 아래로 떨어지게 함 */
    text-align: center; /* 텍스트 중앙 정렬 */
  }

  .purchase-icon {
    margin-right: 0; /* 아이콘 우측 여백 제거 */
    margin-bottom: 10px; /* 아이콘 아래 여백 추가 */
  }
}

.purchase-option.in-app-purchase {
  cursor: pointer;
}
