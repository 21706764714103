.landing-detail-header {
  position: relative;
  margin-bottom: 90px;
}

.level-image {
  width: 100%;
  max-width: 640px;
  height: auto;
  margin: 50px 0;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  color: #282c34;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.header-text h1 {
  width: 100%;
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 20px;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  animation: seesaw 0.5s ease-in-out 5;
}

@keyframes seesaw {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  25% {
    transform: rotate(-5deg) translateY(-5px);
  }
  50% {
    transform: rotate(0deg) translateY(0px);
  }
  75% {
    transform: rotate(5deg) translateY(5px);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}

.level-box {
  width: 100%;
  max-width: 640px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: white;
  overflow: hidden;
}

.level-header {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px;
}

.A {
  background-color: #e15543;
}

.B {
  background-color: #ffb135;
}

.C {
  background-color: #60b848;
}

.D {
  background-color: #009be0;
}

.E {
  background-color: #c46ed9;
}

.level-content {
  padding: 10px;
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  display: block; /* 블록 요소로 설정 */
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.level-next-arrow {
  margin-top: -15px;
  width: 60px;
  height: auto;
  z-index: -1;
}

.level-sections .level-group,
.level-sections .level-next-arrow {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.level-sections .level-group.animate,
.level-sections .level-next-arrow.animate {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease; /* 트랜지션 속도 0.3초 */
}

@media screen and (max-width: 425px) {
  .header-text h1 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 20px;
    animation: seesaw 0.5s ease-in-out 5;
  }
  .highlight-text {
    font-size: 0.7em;
  }
  .level-content {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 375px) {
}
