.levelListDivider img {
  width: 100%; /* 이미지가 부모 div의 100% 가로에 맞게 확장 */
  height: auto; /* 이미지 비율을 유지한 채 높이를 자동으로 설정 */
  opacity: 0; /* 초기 opacity는 0 (숨김 상태) */
  animation: fadeIn 1.5s ease-in-out forwards; /* 애니메이션 적용 */
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 애니메이션 정의 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.levelListDivider img {
  width: 100%; /* 이미지가 부모 div의 100% 가로에 맞게 확장 */
  height: auto; /* 이미지 비율을 유지한 채 높이를 자동으로 설정 */
}
