.program-introduction-steps {
  text-align: center;
  padding: 60px 20px;
  line-height: 1.6;
}

.steps-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.highlight {
  color: #e08a50;
  font-weight: bold;
}

.steps-subtitle {
  font-size: 24px;
  color: #666;
}

.bulletin-header {
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-bottom: 40px; */
  background-color: #1f3044;
  color: white;
  flex-shrink: 0;
  /* width: 260px; */
  border-radius: 40px;
  box-sizing: border-box;
  width: fit-content;
  padding: 10px 25px;
}

.step-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  max-width: 100%;
  box-sizing: border-box;
  gap: 250px;
  margin-top: 100px;
}
.step-box div {
  box-sizing: border-box;
}

.step-content-pic {
  display: flex;
  max-width: 80%;
  /* height: 400px; */
  flex-direction: column;
  align-items: center;
}

.step-image-pic {
  width: 100%;
  /* height: 220px; */
  object-fit: contain;
  max-width: 800px;
  max-height: 500px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
}

.step-description-pic {
  font-size: 24px;
  color: #666;
  line-height: 1.6;
}

@media screen and (max-width: 1080px) {
  .steps-title {
    font-size: 26px;
  }
  .steps-subtitle,
  .step-description-pic {
    font-size: 24px;
  }
  .program-introduction-steps {
    padding: 50px 15px;
  }
  .step-box {
    gap: 150px;
  }
}

@media screen and (max-width: 980px) {
  .steps-title {
    font-size: 24px;
  }
  .steps-subtitle,
  .step-description-pic {
    font-size: 20px;
  }
  .program-introduction-steps {
    padding: 40px 10px;
  }
  .step-box {
    gap: 150px;
  }
}

@media screen and (max-width: 768px) {
  .steps-title {
    font-size: 22px;
  }
  .steps-subtitle,
  .step-description-pic {
    font-size: 16px;
  }
  .program-introduction-steps {
    padding: 30px 5px;
  }
}

@media screen and (max-width: 375px) {
  .steps-title {
    font-size: 20px;
  }
  .steps-subtitle,
  .step-description-pic {
    font-size: 14px;
  }
  .program-introduction-steps {
    padding: 20px 0;
  }
}
