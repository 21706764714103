.section-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 100px; /* Header 높이만큼 내려서 배치 */
}

.science2 {
  background-color: #FFF0CE;
}

.literacy2 {
  background-color: #E3E3FA;
}

.ebook {
  background-color: #EEEADF;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container img {
  width: 100%;
  max-width: 768px; /* 이미지 최대 폭을 1080px로 제한 */
  height: auto;
  border-radius: 8px; /* 모서리를 약간 둥글게 */
  margin-top: 0px;
}
