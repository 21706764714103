/* .hero {
  background: url("/src/assets/images/main_img.png") no-repeat center
    center/cover;
  width: 100vw;
  height: 360px;
  background-color: #f0ede4;
    width: 100vw;
  height: 360px;
  background-color: #eeeadf;
  padding-top: 100px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
} */

.hero-intro-section {
  background: url("/src/assets/images/toddlier_main_img.png") no-repeat center
    center/cover;
  width: 100vw;
  height: 460px;
  background-color: #eeeadf;
  padding-top: 100px;
  position: relative; /* 부모 요소가 포지셔닝 되지 않으면 z-index가 작동하지 않음 */
  z-index: 0; /* 기본 z-index를 설정 */
  display: flex;
  flex-direction: column;
}

.hero-intro-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 560px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1; /* ::before는 부모보다 아래로 설정 */
}

.hero-inner-intro-section {
  position: relative; /* 자식 요소에도 position 설정 */
  z-index: 2; /* 자식 요소가 부모보다 위로 오도록 설정 */
  max-width: 1080px;
  margin: 4% 10%;
  text-align: left;
}

.hero-intro-title {
  font-size: 3.5em;
  font-weight: 700;
  text-align: left;
  margin-top: 2%;
}

.hero-intro-subtitle {
  font-size: 1.2em;
  color: #555;
}

/* 
.hero-content {
  width: 100%;
  height: 100%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  z-index: 500;
}

.title-wrap {
  font-size: 1.2;
  position: absolute;
  bottom: 30%;
  left: 5%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

/* .title-wrap h1 {
  line-height: -1.2;
}

.title-wrap h1 + h1 {
  margin-top: -10px;
}

.title-wrap h2 {
  font-size: 24px;
  margin: 0;
  line-height: 1.2;
}

.title-wrap h2 + h2 {
  margin-top: 5px;
  margin-bottom: 50px;
} */

button {
  font-family: "SB Agro L";
  background-color: #e26d3d;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 20vw;
  height: 40px;
  font-size: 1.2em;
}

button:hover {
  background-color: #ff8c66;
}

/* Tablet 사이즈 이하에서는 가운데 정렬 */
@media screen and (max-width: 768px) {
  .hero-content {
    bottom: auto; /* Tablet 크기에서는 자동 위치 */
    /* top: 50%; */
    transform: translateX(-50%); /* 정확하게 가운데 정렬 */
    align-items: center; /* 아이템을 가운데 정렬 */
    width: 100%;
  }
  .title-wrap {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    left: 50%; /* 가운데 정렬 */
    padding-left: 20px;
  }

  button {
    transition: background-color 0.3s ease;
    width: 40vw;
    height: 3vh;
    font-size: 1.2em;
  }
}

/* Tablet 사이즈 이하에서는 가운데 정렬 */
@media screen and (max-width: 500px) {
  .title-wrap h1 {
    font-size: 2.4em;
  }
}

@media screen and (max-width: 425px) {
  .hero-intro-title {
    font-size: 2.5em;
  }

  .hero-intro-section {
    height: 320px;
  }
}

@media screen and (max-width: 375px) {
  .hero-intro-title {
    font-size: 1.7em;
    padding-bottom: 25%;
  }

  .hero-intro-section {
    height: 320px;
  }
  .hero-intro-subtitle {
    font-size: 1em;
    color: #555;
  }
}
