.onOffline-container {
  padding-top: 50px;
  width: 100%;
  margin-bottom: 100px;
}
.onOffline-container div {
  box-sizing: border-box;
}

.program-intro-container {
  text-align: center;
  padding: 50px 0;
  background-color: #f9f6f0;
  width: 100%;
  box-sizing: border-box;
}

.program-intro-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.highlight_orange {
  color: #de6d3d;
  font-weight: bold;
  font-size: 3rem;
}

.program-intro-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.5;
}

.program-options-button {
  background-color: #de6d3d;
  font-weight: 700;
  color: #fff;
  padding: 5px 30px;
  border-radius: 30px;
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
}

.program-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program-option-cards {
  width: 100%;
  /* background-color: red; */
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 10px;
}

.program-option-card-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  flex: 1;
  width: 100%;
  aspect-ratio: 250/140;
  /* width: 250px;
  height: 140px; */
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 10px;
}

.program-option-title {
  font-size: 1.2rem;
  color: #333;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.program-option-description {
  text-align: center;
  font-size: 0.9rem;
  color: #666;
}

.program-option-card img {
  display: block;
  object-fit: cover;
  width: 100%;
  /* aspect-ratio: 290/220; */
  /* height: 220px; */
  border-radius: 20px;
  border: 1px solid #ddd;
}

.contact-container {
  /* height: 500px; */
  text-align: center;
  padding: 0 20px;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
}

.contact-title {
  font-size: 2.6rem;
  color: #333;
  margin-bottom: 30px;
}

.option-highlight {
  color: #e47936;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 3rem;
}

.contact-title .highlight {
  font-size: 3.6rem;
}

.contact-description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 70px;
  line-height: 1.5;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #de6d3d;
  color: #fff;
  height: 100px;
  width: 280px;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  margin-bottom: 70px;
  box-sizing: border-box;
}

.contact-icon-img {
  border-radius: 10px;
  height: 100%; /* 버튼 높이에 맞춤 */
  margin-right: 20px;
}

.centerAlign {
  display: flex;
  justify-content: center;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.contact-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.program-option-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
}

@media screen and (max-width: 1024px) {
  .program-intro-container,
  .contact-container {
    width: 100%;
  }
  .program-intro-title,
  .contact-title {
    font-size: 2.4rem;
  }
  .highlight {
    font-size: 2.4rem;
  }
  .program-options-button {
    font-size: 1.8rem;
  }
  .program-option-title {
    font-size: 1.1rem;
  }
  .program-option-description {
    font-size: 0.85rem;
  }
  .contact-button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 960px) {
  .program-intro-container,
  .contact-container {
    width: 100%;
  }
  .program-intro-title,
  .contact-title {
    font-size: 1.8rem;
  }
  .highlight {
    font-size: 2rem;
  }
  .program-intro-subtitle,
  .contact-description {
    font-size: 0.9rem;
  }
  .program-options-button {
    font-size: 1.6rem;
  }
  .program-option-title {
    font-size: 1rem;
  }
  .program-option-description {
    font-size: 0.8rem;
  }
  .contact-button {
    font-size: 0.95rem;
  }

  .program-option-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    place-items: center;
  }

  @media screen and (max-width: 768px) {
    .program-option-cards {
      grid-template-columns: repeat(2, 1fr);
    }

    .highlight_orange {
      font-size: 1.3em;
    }
  }

  @media screen and (max-width: 480px) {
    .program-option-cards {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 768px) {
  .program-intro-subtitle {
    font-size: 1em;
    font-weight: 500;
  }
  .program-intro-container,
  .contact-container {
    width: 100%;
  }
  .program-intro-title,
  .contact-title {
    font-size: 2rem;
  }
  .highlight {
    font-size: 2rem;
  }
  .program-options-button {
    font-size: 1.4rem;
  }
  .program-option-title {
    font-size: 0.9rem;
  }
  .program-option-description {
    font-size: 0.85rem;
  }
  .contact-button {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 425px) {
  .program-intro-container,
  .contact-container {
    width: 100%;
  }
  .program-intro-title,
  .contact-title {
    font-size: 1.2rem;
  }
  .highlight {
    font-size: 1.5rem;
  }

  .contact-title .highlight {
    font-size: 1.6rem;
  }

  .program-options-button {
    font-size: 1.2rem;
  }
  .program-option-title {
    font-size: 0.8rem;
  }
  .program-option-description {
    font-size: 1rem;
  }
  .contact-button {
    font-size: 0.85rem;
  }

  .highlight_orange {
    font-size: 1.1em;
  }
}
