.levelListDivider img {
  width: 100%; /* 이미지가 부모 div의 100% 가로에 맞게 확장 */
  height: auto; /* 이미지 비율을 유지한 채 높이를 자동으로 설정 */
  opacity: 0; /* 초기 opacity는 0 (숨김 상태) */
  animation: fadeIn 1.5s ease-in-out forwards; /* 애니메이션 적용 */
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 애니메이션 정의 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.levelListDivider img {
  height: auto; /* 이미지 비율을 유지한 채 높이를 자동으로 설정 */
}

.level-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-bottom: 8%;
}

.level-title {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
  order: 1;
}

.level-ar {
  font-size: 1.2em;
  color: #555;
}

.level-text {
  font-size: 1.2em;
  color: #333;
  line-height: 1.5em;
}

.level-description {
  width: 75%; /* level-description의 너비를 50%로 설정 */
}

.align-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.no-flex {
  display: block;
  width: 15%;
}

.level-item img {
  margin: auto 10%;
  max-width: 25%;
}

.color_a {
  color: #e15543;
}

.color_b {
  color: #ffb135;
}

.color_c {
  color: #60b848;
}

.color_d {
  color: #009be0;
}

.color_e {
  color: #c46ed9;
}

.horizontal_line_a {
  border-top: 1px solid #e15543;
  margin: 10px 0;
  width: 100%;
}

.horizontal_line_b {
  border-top: 1px solid #ffb135;
  margin: 10px 0;
  width: 100%;
}

.horizontal_line_c {
  border-top: 1px solid #60b848;
  margin: 10px 0;
  width: 100%;
}

.horizontal_line_d {
  border-top: 1px solid #009be0;
  margin: 10px 0;
  width: 100%;
}

.horizontal_line_e {
  border-top: 1px solid #c46ed9;
  margin: 10px 0;
  width: 100%;
}

.level-list-title {
  font-size: 3em;
  font-weight: 900;
  text-align: center;
  padding-top: 100px;
  border-radius: 80px 80px 180px 180px;
  width: 90%;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column; /* 세로로 배치 */
  justify-content: center;
  align-items: center; /* 수평 중앙 정렬 */
}

.mt {
  margin-top: 45px;
  font-weight: 900;
  line-height: 2em;
  margin-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .level-item {
    flex-direction: column; /* 수직 방향으로 배치 */
  }

  .no-flex {
    width: 100%; /* 가로폭을 100%로 설정 */
    margin-bottom: 20px; /* no-flex와 level-description 사이에 간격 추가 */
  }

  .level-description {
    width: 100%; /* 가로폭을 100%로 설정 */
  }
}
