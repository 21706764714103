.footer {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 세로 중앙 정렬 */
  background-color: white;
  color: #777;
  padding: 50px;
  margin: 0 auto; /* 왼쪽과 오른쪽 여백 자동 조정으로 가운데 정렬 */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2); /* 위쪽 그림자 추가 */
}

.displayWide {
  width: 100%;
}

.display1080 {
  display: none;
}

.footer-left {
  width: 57%;
  padding: 40px;
}

.footer-right {
  width: 43%;
  padding: 30px;
  display: flex; /* Flexbox로 한 줄 배치 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
}

hr {
  height: 10vh;
  width: 0.1vw;
  border-width: 0;
  background-color: #ddd;
  margin: 0 20px; /* 좌우 여백을 추가하여 구분선이 붙지 않도록 */
}

/* Footer company information */
.footer-company span {
  font-weight: bold;
  display: block;
  margin-bottom: 30px;
}

.footer-info,
.footer-contact {
  font-size: 0.7em;
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.footer-info span:not(:first-child),
.footer-contact span:not(:first-child) {
  position: relative;
  padding-left: 15px;
}

.footer-info span:not(:first-child)::before,
.footer-contact span:not(:first-child)::before {
  content: "|";
  position: absolute;
  left: 2%;
  color: #777;
}

/* 줄 바꿈이 일어났을 때 첫 번째 항목에는 | 기호가 나타나지 않도록 */
.footer-info span:first-child::before,
.footer-contact span:first-child::before {
  content: "a";
}

/* footer-left에서도 | 기호를 첫 번째 항목에서 숨기도록 적용 */
.footer-left .footer-info span:not(:first-child)::before,
.footer-left .footer-contact span:not(:first-child)::before {
  content: "|";
  position: absolute;
  left: 2%;
  color: #777;
}

.footer-left .footer-info span:first-child::before,
.footer-left .footer-contact span:first-child::before {
  content: "";
}

@media screen and (max-width: 1080px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0 auto;
  }

  hr {
    display: none;
  }

  .footer-info,
  .footer-contact {
    flex-direction: column;
    text-align: center;
  }

  .footer-info span,
  .footer-contact span {
    padding-left: 0;
    margin-bottom: 10px;
  }

  .footer-info span:not(:first-child)::before,
  .footer-contact span:not(:first-child)::before {
    content: "";
  }

  .displayWide {
    display: none;
  }

  .display1080 {
    display: block;
    margin-bottom: 30px;
  }
}

/* 링크의 밑줄 없애기 */
.footer-right span {
  text-decoration: none; /* 링크에 밑줄 없애기 */
}

.divider {
  color: #777;
  margin: 0 15px; /* 좌우 간격 조정 */
}

@media screen and (max-width: 768px) {
  .footer-right {
    font-size: 12px;
  }
}
