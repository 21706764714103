.hamburger-menu {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50px;
  right: 20px;
  padding: 20px;
  gap: 10px;
}

.hamburger-menu a {
  color: white;
  text-align: right;
}
