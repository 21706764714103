.landing-detail04-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%; /* 화면의 80% 너비로 설정 */
  min-width: 300px;
  margin: 0px auto; /* 가운데 정렬 */
  /* background-color: blue; */
}

/* 이 landing-detail04-container 를 화면 사이즈에 따라 width 를 조절해야 한다 */

.idc-carousel {
  height: 400px;
  width: 90%;
  overflow: hidden;
  margin-bottom: 60px;
  /* background-color: red; */
}

.landing-detail04-header {
  text-align: center;
  /* margin: 30px auto; */
}

.landing-detail04-header .big-font {
  font-size: 2.2em;
}

.landing-detail04-header .margin-bottom {
  margin-bottom: 160px;
}

.paddingInsert .big-font {
  font-size: 2.2em !important;
}

.paddingInsert .highlight-black {
  font-size: 1.5em;
}

.highlight {
  color: #568d72; /* 초록색 */
}

.divider_title {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 25px auto 90px;
  border-top: 1px solid rgba(85, 131, 76, 0.5);
}

.divider_title .diamond {
  position: absolute;
  top: -7px; /* Position the diamond */
  left: 50%;
  transform: translateX(-50%);
  width: 12px;
  height: 12px;
  background-color: #55834c; /* Diamond color */
  transform: rotate(45deg); /* Make it a diamond */
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    box-shadow: 0 0 5px #55834c, 0 0 10px #55834c,
      0 0 15px rgba(85, 131, 76, 0.5);
  }
  50% {
    box-shadow: 0 0 15px #66c57f, 0 0 25px #66c57f,
      0 0 30px rgba(102, 197, 127, 0.5);
  }
  100% {
    box-shadow: 0 0 5px #55834c, 0 0 10px #55834c,
      0 0 15px rgba(85, 131, 76, 0.5);
  }
}

.smallTitle-container {
  display: flex;
  justify-content: center;
}

.icon-single img {
  width: 90px; /* 아이콘 크기 */
  height: 90px; /* 아이콘 크기 */
  padding: 20px 20px; /* 각 아이콘의 내부 여백 */
  object-fit: contain; /* 이미지 비율 유지 */
  transition: transform 0.3s ease-in-out; /* Smooth scaling */
}

.image-message {
  background-color: #568d72; /* 버튼 배경색 */
  color: #fff; /* 텍스트 색상 */
  font-size: 1.2em; /* 폰트 크기 */
  font-weight: bold;
  border: none; /* 테두리 제거 */
  padding: 15px 80px;
  border-radius: 10px;
  margin: 20px;
}

.slider {
  margin-bottom: 120px;
}

.slider-image img {
  width: 65%;
  min-width: 300px;
  border-radius: 10px;
  transition: opacity 0.5s ease-in-out;
}

video {
  width: 80%;
  min-width: 300px;
  margin-top: 90px auto;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.imageToSoundItem {
  width: 90%;
  min-width: 300px;
  margin-top: 90px auto;
  border-radius: 10px;
  cursor: pointer; /* 마우스 올릴 때 커서 변경 */
  transition: transform 0.3s ease-in-out; /* 부드러운 크기 변화 */
}

@keyframes pulseText {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3); /* 텍스트 120% 확대 */
  }
  100% {
    transform: scale(1);
  }
}

.icon-single-line {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개씩 배치 */
  gap: 20px; /* 아이콘 간격 */
  justify-items: center; /* 아이콘을 중앙 정렬 */
  margin-top: -90px;
}

.icon-single {
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease-in-out; /* 부드러운 확대 */
}

.icon-single:hover img {
  animation: pulsate 0.8s infinite alternate ease-in-out; /* 호버 시 확대/축소 */
}

.icon-single.active img {
  transform: scale(1.5); /* 클릭한 아이콘 150% */
  transition: transform 0.3s ease-in-out;
}

.SampleImage {
  width: 100%; /* 이미지 크기를 부모 크기에 맞게 설정 */
  object-fit: cover; /* 이미지 비율 유지 */
  transition: opacity 0.5s ease-in-out; /* 부드러운 fade-out */
  border-radius: 10px;
}

.SampleScore {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 100%; /* 이미지의 크기와 일치하도록 설정 */
  background-color: rgba(0, 0, 0, 0.8); /* 배경 색상 */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  opacity: 0; /* 초기 상태: 숨김 */
  transition: opacity 0.5s ease-in-out; /* 부드러운 fade-in/out */
  z-index: 1; /* 이미지 위에 겹치도록 */
}

.Sample-overlay {
  font-size: 1.2em;
  position: relative;
  bottom: 80px;
  width: 380px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8); /* 하얀색 반투명 */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333; /* 텍스트 색상 */
  font-weight: bold; /* 텍스트 굵기 */
  animation: pulseText 4s infinite; /* 텍스트 크기 애니메이션 */
  transition: transform 0.3s ease-in-out;
  z-index: 2;
  margin: 0 auto;
}

.icon-single-line {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  max-width: 480px;
}

.icon-single {
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease-in-out; /* Smooth scaling */
}

@media screen and (max-width: 1080px) {
  .icon-single-line {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 icons per row */
    gap: 0px;
    justify-items: center; /* Center the icons in the grid */
  }

  .icon-single img {
    width: 90px;
    transition: transform 0.3s ease-in-out; /* Smooth scaling */
  }

  .slider-image img {
    width: 90%;
  }

  .Sample-overlay {
    font-size: 0.8em;

    bottom: 60px;
    width: 220px;
    height: 40px;
  }
}

.bulletin-header {
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #1f3044;
  color: white;
  width: 40%;
  border-radius: 40px;
}

.comparison-board-describe {
  width: 100%;
  color: #4c4c4c;
  font-weight: 700;
  font-size: 1.3em;
  margin-bottom: 120px;
  line-height: 1.5;
}

@media (max-width: 1024px) {
  .comparison-board-describe {
    font-size: 1.2em;
  }

  .justShow {
    display: none;
  }

  .normalize {
    display: flex;
    justify-content: center;
    line-height: 1.8;
  }
}

@media (max-width: 768px) {
  .SampleImage {
    width: 100%; /* 이미지 크기를 부모 크기에 맞게 설정 */
  }
  .program-introduction-steps {
    padding: 35px 14px;
  }
  .steps-title {
    font-size: 22px;
  }
  .steps-subtitle {
    font-size: 16px;
  }

  .bulletin-header {
    font-size: 1.5rem;
    width: 60%;
  }

  .step-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .comparison-board-describe {
    font-size: 1em;
  }
  .icon-single img {
    width: 70px;
    transition: transform 0.3s ease-in-out; /* Smooth scaling */
  }

  .slider-image img {
    width: 90%;
  }

  .Sample-overlay {
    font-size: 0.6em;
    bottom: 50px;
    width: 180px;
    height: 30px;
  }

  .bulletin-header {
    font-size: 1.2rem;
    width: 90%;
  }

  /*  */
  .icon-single img {
    padding: 10px 10px;
  }
}

@media screen and (max-width: 375px) {
}

.marginMinus {
  margin-top: -150px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
}
