.program-introduction-steps {
  text-align: center;
  padding: 40px 15px;
  line-height: 1.6;
}

.steps-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.highlight {
  color: #e08a50;
  font-weight: bold;
}

.steps-subtitle {
  font-size: 24px;
  color: #666;
}

.bulletin-header {
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  background-color: #1f3044;
  color: white;
  width: 220px;
  border-radius: 40px;
  margin-top: -40px;
}

.step-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  max-width: 100%;
  box-sizing: border-box;
  gap: 250px;
  margin-top: 100px;
}

.step-content {
  display: flex;
  max-width: 80%;
  /* height: 400px; */
  flex-direction: column;
  align-items: center;
}

.step-image {
  width: 100%;
  /* height: 220px; */
  object-fit: contain;
  max-width: 800px;
  max-height: 500px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
}

.step-description {
  font-size: 24px;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 1080px) {
  .program-introduction-steps {
    padding: 50px 18px;
  }
  .steps-title {
    font-size: 26px;
  }
  .steps-subtitle {
    font-size: 24px;
  }
  .bulletin-header {
    font-size: 1.7rem;
    width: 210px;
  }
  /* .step-box {
    padding: 18px;
    max-width: 95%;
  } */

  .step-description {
    font-size: 24px;
  }
  .step-box {
    gap: 150px;
  }
}

@media (max-width: 980px) {
  .program-introduction-steps {
    padding: 45px 16px;
  }
  .steps-title {
    font-size: 25px;
  }
  .steps-subtitle {
    font-size: 20px;
  }
  .bulletin-header {
    font-size: 1.6rem;
    /* width: 205px; */
  }
  .step-box {
    gap: 150px;
  }

  .step-description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .program-introduction-steps {
    padding: 35px 14px;
  }
  .steps-title {
    font-size: 22px;
  }
  .steps-subtitle {
    font-size: 16px;
  }

  .bulletin-header {
    font-size: 1.5rem;
    width: 70%;
  }

  .step-description {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .program-introduction-steps {
    padding: 30px 10px;
  }
  .steps-title {
    font-size: 20px;
  }
  .steps-subtitle {
    font-size: 14px;
  }
  .bulletin-header {
    font-size: 1.4rem;
    /* width: 180px; */
  }

  .step-description {
    font-size: 14px;
  }
}
