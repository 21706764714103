/* 전체 가격 페이지 */
.pricing {
  padding: 20px;
}

/* 이미지 부분 */
.grid-container-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 이미지 섹션은 5개의 열로 나누기 */
  grid-template-rows: repeat(1, 1fr); /* 가격 섹션은 4개의 열로 나누기 */
  gap: 20px; /* 이미지 간 간격 설정 */
}

.grid-item {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  flex-direction: column; /* 이미지와 텍스트가 세로로 배치되도록 설정 */
  margin: 20px auto;
}

.grid-item img {
  width: 100%; /* 이미지가 grid-item의 크기에 맞게 자동으로 조절 */
  max-width: 200px; /* 이미지를 너무 크게 만들지 않도록 제한 */
  height: auto; /* 비율을 유지하면서 크기를 조절 */
}

/* 가격 정보 부분 */
.grid-container-prices {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 가격 섹션은 5개의 열로 나누기 */
  grid-template-rows: repeat(4, 1fr); /* 가격 섹션은 4개의 열로 나누기 */
  gap: 5px; /* 가격 항목 간 간격 설정 */
}

/* 가격 항목 스타일 */
.grid-item-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.old-price {
  font-size: 0.7em; /* 작은 글씨 크기 */
  text-decoration: line-through; /* 취소선 */
  color: #888;
  margin-right: 3px; /* 간격을 더 줄임 */
}

.new-price {
  font-size: 1.1em; /* 작은 크기로 설정 */
  font-weight: bold;
}

.price {
  display: flex;
  flex-direction: column; /* 두 span을 세로로 배치 */
  align-items: center; /* 가운데 정렬 */
}

/* 가격 항목 텍스트 중앙 정렬 */
.grid-item > span {
  display: flex;
  justify-content: center; /* 가로로 가운데 정렬 */
  align-items: center; /* 세로로 가운데 정렬 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.price-title {
  padding: 10px;
  font-size: 1em;
}

.size_check {
  font-size: 2.1em;
  line-height: 1.5;
}

@media screen and (max-width: 960px) {
  .price-title {
    font-size: 1em;
  }
  .size_check {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 768px) {
  .price-title {
    padding: 10px;
    font-size: 0.85em;
  }
  .size_check {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 640px) {
  .price-title {
    padding: 2px;
    font-size: 0.65em;
  }
  .size_check {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 375px) {
  .price-title {
    font-size: 0.9rem;
  }
  .size_check {
    font-size: 0.9em;
  }
}
