/* PrivacyPolicyModal.css */

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은색 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* 배경 스크롤 방지 */
  z-index: 90;
}

.ReactModal__Content {
  /* width: 90% !important; */
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto; /* 모달 내부 스크롤 */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.ReactModal__Content h2 {
  margin-top: 0;
}

.ReactModal__Content button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
