.landing-detail-header {
  position: relative;
  margin-bottom: 90px;
}

.header-image-container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}

.banner-image {
  width: 100%;
  height: auto;
}

.header-text-landing01 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #282c34;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.header-text-landing01 h1 {
  font-size: 2.1rem;
  font-weight: normal;
  margin-bottom: 20px;
  word-wrap: break-word;
  white-space: normal;
  animation: seesaw 0.5s ease-in-out 5;
}

@keyframes seesaw {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  25% {
    transform: rotate(-5deg) translateY(-5px);
  }
  50% {
    transform: rotate(0deg) translateY(0px);
  }
  75% {
    transform: rotate(5deg) translateY(5px);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}

.second-section {
  margin-top: 50px;
}

.second-title {
  font-size: 2.6rem;
}

@media screen and (max-width: 1024px) {
  .header-text-landing01 {
    width: 65%;
    left: 40%;
    transform: translate(-10%, -50%);
  }
}

@media screen and (max-width: 960px) {
  .header-text-landing01 {
    left: 30%;
    transform: translate(0%, -50%);
  }

  .banner-image {
    width: 100vw;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .header-text-landing01 {
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .header-text-landing01 h1 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 640px) {
  .header-text-landing01 h1 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 425px) {
  .header-text-landing01 h1 {
    font-size: 1em;
  }

  .header-text-landing01 {
    width: 60%;
    top: 50%;
    left: 73%;
    transform: translate(-60%, -50%);
  }
}

@media screen and (max-width: 375px) {
  .header-text-landing01 h1 {
    font-size: 1em;
  }

  .header-text-landing01 {
    position: absolute;
    width: 65%;
    top: 50%;
    left: 70%;
    transform: translate(-60%, -50%);
  }
}

@media screen and (max-width: 350px) {
  .header-text-landing01 h1 {
    font-size: 0.9em;
  }

  .header-text-landing01 {
    position: absolute;
    width: 70%;
    top: 50%;
    left: 70%;
    transform: translate(-60%, -50%);
  }
}
