.contents-party {
  display: flex;
  justify-content: center; /* 가로 정렬 */
  align-items: center; /* 세로 정렬 */
  margin: 120 auto;
}

.content-item02 {
  display: flex;
}

.landing-detail02 {
  position: relative; /* 자식 요소가 절대 위치를 가질 수 있도록 */
  padding: 20px 20px;
  font-family: "Noto Sans", sans-serif;
  color: #333;
  text-align: center;
  background-color: #eeeadf;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center; /* 배경 이미지 위치 조정 */
}

.wrapYellow {
  margin: 30px 0;
}

/* 내용이 오버레이 위에 표시되도록 z-index 조정 */
.landing-detail02 > * {
  position: relative;
  z-index: 2; /* 컨텐츠가 오버레이 위에 위치하도록 설정 */
}

/* 타이틀 배경 이미지 스타일 */
.title-banner {
  background-image: url("../../assets/images/smallTitleBackgroundBlue.png"); /* 배경 이미지 추가 */
  background-repeat: no-repeat;
  background-size: 220px 49px;
  background-position: center;
  padding: 15px 0px; /* 텍스트가 이미지 중앙에 위치하도록 */
  text-align: center;
  margin-bottom: 20px;
  margin: 0 auto;
}

.title-banner h2 {
  color: #fff;
}

.contents-banner {
  background-image: url("../../assets/images/contents-banner_yg.png"); /* 배경 이미지 추가 */
  background-repeat: no-repeat;
  background-size: 320px 125px;
  background-position: center;
  padding: 60px 60px; /* 텍스트가 이미지 중앙에 위치하도록 */
  text-align: center;
  display: inline-block; /* inline-block으로 설정하여 텍스트들이 나란히 배치되도록 */
}

.comparison-impact-box {
  /* background-image: url("../../assets/images/bubble-text-brown.png");  */
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px 0;
  opacity: 100%;
}

.comparison-impact-box h2 {
  position: relative;
  top: -15px;
  text-align: center;
  color: #333;
  font-size: 2.2em;
  font-weight: 900;
}

.comparison-board-01 {
  background-image: url("../../assets/images/comparisonBoard01.png"); /* 배경 이미지 추가 */
  background-repeat: no-repeat;
  background-size: 400px 308px;
  background-position: center;
  padding: 30px 70px; /* 텍스트가 이미지 중앙에 위치하도록 */
  text-align: center;
  margin-bottom: 20px;
  margin: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
  border-radius: 20px;
  background-color: #f9f9f9;
  opacity: 100%;
}
.comparison-board-02 {
  background-image: url("../../assets/images/comparisonBoard02.png"); /* 배경 이미지 추가 */
  background-repeat: no-repeat;
  background-size: 400px 308px;
  background-position: center;
  padding: 30px 70px; /* 텍스트가 이미지 중앙에 위치하도록 */
  text-align: center;
  margin-bottom: 20px;
  margin: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
  border-radius: 20px;
  background-color: #f9f9f9;
  opacity: 100%;
}

.comparison-board-h2 {
  position: relative;
  top: -25px;
  color: #fff;
  font-weight: 900;
}

.comparison-board-h1 {
  color: #4c4c4c;
  font-weight: 900;
  font-size: 3em;
}

.comparison-board-p {
  width: 210px;
  color: #4c4c4c;
  font-weight: 700;
  font-size: 1em;
}

/* 크기 변화를 위한 애니메이션 정의 */
@keyframes scale-up-down {
  0%,
  100% {
    transform: scale(1); /* 기본 크기 */
  }
  50% {
    transform: scale(1.5); /* 150% 크기 */
  }
}

/* 애니메이션 클래스 */
.scaling-text {
  display: inline-block; /* 인라인 블록으로 설정 */
  font-size: inherit; /* 부모 요소의 폰트 크기를 따름 */
  animation: scale-up-down 3s infinite; /* 3초마다 반복 */
}

.contents-banner-none {
  margin: 0 auto;
  display: inline-block; /* inline-block으로 설정하여 텍스트들이 나란히 배치되도록 */
}

.text-container {
  display: flex;
  justify-content: space-between; /* 텍스트를 왼쪽과 오른쪽 끝에 배치 */
  align-items: center; /* 수직 가운데 정렬 */
  font-size: 1.8rem;
  font-weight: 900;
  position: relative;

  width: 300px;
  height: 125px;
}

.left-text,
.right-text {
  font-size: 1.8rem;
  font-weight: 900;
  color: white;
  text-align: center;
}

.subTitle02 {
  text-align: center;
  padding: 20px;
  font-size: 1.3em;
  font-weight: 700;
}

.landing-detail-02-paragraph {
  font-size: 2.4em;
}

.yellowFont {
  color: #ffb742;
  padding: 5px;
  font-size: 1.2em;
  font-weight: 900;
}

.landing-detail-header02 p {
  font-weight: 500;
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 20px;
}

.nohighlight {
  font-weight: 500;
  font-size: 2.2rem;
  color: #f5f5f7;
  margin-bottom: 20px;
}

.icon-container {
  display: flex;
  flex-wrap: wrap; /* 아이콘을 2개씩 다음 줄로 내려가도록 설정 */
  justify-content: center;
}

.icon-item {
  width: 100%;
  height: 100%;
  padding: 30px 80px;
}

.icon-item img {
  width: 80px;
  height: 80px;
  margin: 5px;
  object-fit: contain;
  transform: scale(1);
  transition: transform 0.5s ease;
}

.icon-item img.animate-icon {
  animation: scale-up-down 2s ease forwards 3;
}

@keyframes scale-up-down {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* 120% 확대 */
  }
  100% {
    transform: scale(1); /* 원래 크기로 복구 */
  }
}

@media screen and (max-width: 768px) {
  .comparison-impact-box h2 {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 767px) {
  .contents-party {
    display: block;
  }

  .comparison-impact-box h2 {
    font-size: 1.4em;
  }

  .content-item02 {
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 768px) {
  .contents-banner {
    background-image: url("../../assets/images/contents-banner_yg.png"); /* 배경 이미지 추가 */
    background-repeat: no-repeat;
    background-size: 280px 125px;
    background-position: center;
    padding: 30px 30px; /* 텍스트가 이미지 중앙에 위치하도록 */
    text-align: center;
    display: inline-block; /* inline-block으로 설정하여 텍스트들이 나란히 배치되도록 */
  }
}

@media screen and (max-width: 640px) {
  .landing-detail-02-paragraph {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 425px) {
  .comparison-board-01 {
    padding: 30px 40px;
    background-size: 330px 308px;
  }

  .comparison-board-02 {
    padding: 30px 40px;
    background-size: 330px 308px;
  }

  .text-container {
    max-width: 220px;
  }

  .text-container .right-text {
    font-size: 1.1rem;
  }

  .text-container .left-text {
    font-size: 1.1rem;
  }

  .icon-item {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .comparison-impact-box {
    background-size: 100%;
  }

  .comparison-impact-box h2 {
    font-size: 1.3em;
  }

  .contents-party {
    display: block;
  }

  .justShow {
    display: none;
  }

  .normalize {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }

  .landing-detail-02-paragraph {
    font-size: 2.4em;
  }
}

@media screen and (max-width: 375px) {
  .landing-detail-02-paragraph {
    font-size: 2em;
  }
}

@media screen and (max-width: 350px) {
  .landing-detail-02-paragraph {
    font-size: 2.1em;
  }

  .comparison-impact-box h2 {
    font-size: 1.25em;
  }
}
